// Config object to be passed to Msal on creation
export const msalConfig = {
  auth: {
    clientId: '90e1a2b1-ca90-4aa2-ba88-dd85acbae7b1',
    authority:
      'https://login.microsoftonline.com/bbe4f244-c309-4414-8499-4c27bf1c57d7',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  myGroups:
    'https://graph.microsoft.com/v1.0/me/transitiveMemberOf/microsoft.graph.group?$count=true',
  myPhoto: 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value',
};
