// Necessary if using App Router to ensure this file runs on the client
'use client';

import { datadogRum } from '@datadog/browser-rum';

const env = process.env.NEXT_PUBLIC_DATA_DOG_ENV;

if (env) {
  datadogRum.init({
    applicationId: 'b88683cb-d9df-4477-b54a-070f720cee6e',
    clientToken: 'pub4c69d600380904456ec011a35813aa25',
    site: 'us3.datadoghq.com',
    service: 'samsson',
    env: env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.NEXT_PUBLIC_BUILD_ID || undefined,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    // Specify URLs to propagate trace headers for connection between RUM and backend trace
    allowedTracingUrls: [
      {
        match: 'https://api.containers.com/',
        propagatorTypes: ['tracecontext'],
      },
      {
        match: 'https://api-alpha.containers.com/',
        propagatorTypes: ['tracecontext'],
      },
      {
        match: 'https://api-test.containers.com/',
        propagatorTypes: ['tracecontext'],
      },
      {
        match: 'https://api-qa.containers.com/',
        propagatorTypes: ['tracecontext'],
      },
      { match: 'http://localhost:3555/', propagatorTypes: ['tracecontext'] },
    ],
  });
}

export default function DatadogInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
