import { useReducer } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import Script from 'next/script';
import { MsalProvider } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';

import { CustomNavigationClient } from '../helpers/navigation-client';
import { msalInstance } from '../helpers/ms-graph-api';
import UserContext from '../context/user-context';
import reducer from '../context/reducer';
import SiteLayout from '../components/layout';
import { Notification } from '../components/shared/notification';
import ErrorBoundary from '../components/shared/error-boundary';
import DatadogInit from '../shared/datadog-init';
import '../styles/globals.css';

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const navigationClient = new CustomNavigationClient(router);
  msalInstance.setNavigationClient(navigationClient);

  const initialState = {
    user: {},
    groups: [],
    profilePic: null,
    token: '',
    userLoaded: false,
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <Script src='/js-print/JSPrintManager.js' strategy='beforeInteractive' />
      <Head>
        <title>PAM</title>
        <meta
          name='viewport'
          content='minimum-scale=1, initial-scale=1, width=device-width'
        />
      </Head>
      <DatadogInit />
      <MsalProvider instance={msalInstance}>
        <UserContext.Provider value={{ state, dispatch }}>
          <Notification>
            <SiteLayout {...pageProps}>
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            </SiteLayout>
          </Notification>
        </UserContext.Provider>
      </MsalProvider>
    </>
  );
}

export default MyApp;
