import { useState, useContext, createContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  Snackbar,
  Alert,
  Grow,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  ThemeProvider,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import { datadogRum } from '@datadog/browser-rum';
import { defaultTheme, GridItem } from './ui-extension';

export const NotificationContext = createContext({});

// TODO: This context should be renamed as "AppContext".
export function useNotificationContext() {
  return useContext(NotificationContext);
}

export const Notification = ({ children }) => {
  const router = useRouter();
  const { query } = router;

  const [notification, setNotificationState] = useState({
    open: false,
  });
  const [cache, setCache] = useState({});
  const [queryParameters, setQueryParameters] = useState({});
  const updateCache = (object) => {
    setCache((prev) => ({ ...prev, ...object }));
  };

  useEffect(() => {
    setQueryParameters({ ...query });
  }, [query]);

  const updateQueryParameters = (queries = {}) => {
    // Query strings are always passed in as lower case from P21 ribbon metrics
    if (!queries) return;

    let isQueryParameterUpdated = false;

    for (const key in queries) {
      if (query[key] === queries[key]) {
        continue;
      }

      isQueryParameterUpdated = true;

      if (!queries[key] && queries[key] !== 0) {
        delete query[key];
      } else {
        query[key] = queries[key];
      }
    }

    if (isQueryParameterUpdated) {
      router.push(router);
    }
  };

  const showErrorNotification = (e, title) => {
    console.error(e);

    const errorData = e.response?.data;
    let message;

    // Handle Axios error response;
    if (errorData) {
      message = `${
        typeof errorData === 'string'
          ? errorData
          : errorData.message || JSON.stringify(errorData)
      }`;
    } else {
      message = e.toString();
    }

    setNotificationState((prev) => ({
      ...prev,
      open: true,
      message: message,
      title: title,
      severity: 'error',
    }));

    datadogRum.addError(e);
  };

  const handleException = (e, title) => {
    if (
      e.response?.data?.type === 'application/json' &&
      e.response?.data?.arrayBuffer &&
      e.response?.data?.text
    ) {
      e.response.data.text().then((text) => {
        e.response = e.response || {};
        e.response.data = JSON.parse(text);

        showErrorNotification(e, title);
      });
      return;
    }

    showErrorNotification(e, title);
  };

  const openDialog = ({
    handleConfirm,
    confirmText = 'Yes',
    handleCancel,
    cancelText = 'No',
    title,
    message,
    onClose,
    content,
    hideActions,
    fullWidth,
  }) => {
    setNotificationState((prev) => ({
      ...prev,
      open: true,
      useDialog: true,
      message: message,
      title: title,
      handleConfirm: handleConfirm,
      confirmText: confirmText,
      handleCancel: handleCancel,
      cancelText: cancelText,
      onClose: onClose,
      content,
      hideActions,
      fullWidth,
    }));
  };

  const closePopup = () => {
    setNotificationState({ open: false });
  };

  const handleConfirm = () => {
    if (notification.handleConfirm) {
      notification.handleConfirm();
    }
    closePopup();
  };

  const handleCancel = () => {
    if (notification.handleCancel) {
      notification.handleCancel();
    }
    closePopup();
  };

  const onClose = () => {
    if (notification.onClose) {
      notification.onClose();
    }
    closePopup();
  };

  const showMessage = ({ message, title, severity = 'success' }) => {
    setNotificationState((prev) => ({
      ...prev,
      open: true,
      message: message,
      title: title,
      severity: severity,
    }));
  };

  const useExceptionHandler = (action, finalAction) => {
    return async (...params) => {
      let response;

      try {
        response = await action(...params);
      } catch (error) {
        handleException(error);
      }
      await finalAction?.();

      return response;
    };
  };

  const sharedContext = {
    useExceptionHandler,
    handleException,
    openDialog,
    showMessage,
    closePopup,
    cache,
    updateCache,
    queryParameters,
    updateQueryParameters,
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <NotificationContext.Provider value={sharedContext}>
        {children}
        {notification.useDialog ? (
          <Dialog
            open={notification.open}
            onClose={onClose}
            TransitionComponent={Grow}
            maxWidth='xl'
            fullWidth={!!notification.fullWidth}
          >
            {notification.title && (
              <DialogTitle>{notification.title}</DialogTitle>
            )}
            {notification.message && (
              <DialogContent>
                <DialogContentText>{notification.message}</DialogContentText>
              </DialogContent>
            )}
            {notification.content && (
              <DialogContent>{notification.content}</DialogContent>
            )}
            {!notification.hideActions && (
              <DialogActions>
                <Grid
                  container
                  justifyContent='space-between'
                  sx={{ padding: '10px' }}
                >
                  <GridItem>
                    <Button
                      variant='outlined'
                      autoFocus
                      onClick={handleConfirm}
                    >
                      {notification.confirmText}
                    </Button>
                  </GridItem>
                  <GridItem>
                    <Button
                      variant='outlined'
                      onClick={handleCancel}
                      color='error'
                    >
                      {notification.cancelText}
                    </Button>
                  </GridItem>
                </Grid>
              </DialogActions>
            )}
          </Dialog>
        ) : (
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={notification.open}
            onClose={onClose}
            message={notification.message}
            key={uuidv4()}
            autoHideDuration={notification.autoHideDuration || null}
            TransitionComponent={Grow}
          >
            {notification.severity && (
              <Alert
                variant='standard'
                elevation={6}
                onClose={onClose}
                severity={notification.severity}
                sx={{ minWidth: '25vw' }}
              >
                {notification.message}
              </Alert>
            )}
          </Snackbar>
        )}
      </NotificationContext.Provider>
    </ThemeProvider>
  );
};
