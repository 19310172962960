import { useState } from 'react';
import Loader from './loader';
import { Icon } from '@mui/material';

const FaIcon = (props) => {
  const [loading, setLoading] = useState(false);
  const handleClick = async () => {
    setLoading(true);
    await props.onClick();
    setLoading(false);
  };
  return loading ? (
    <Loader />
  ) : (
    <Icon
      role='img'
      style={{
        margin: '0px 5px',
        ...props.style,
      }}
      fontSize='small'
      onClick={props.onClick ? () => handleClick() : null}
    >
      {props.icon}
    </Icon>
  );
};

export default FaIcon;
