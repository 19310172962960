import axios from 'axios';
import { refreshAccessToken } from '../helpers/ms-graph-api';

export const nodeApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_NODE_API,
  headers: {
    'Content-Type': 'application/json',
    changeOrigin: true,
  },
});

export const nodeApiBlob = axios.create({
  baseURL: process.env.NEXT_PUBLIC_NODE_API,
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/json',
  },
});

const includeAzureJwt = async (config) => {
  const token = await refreshAccessToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

nodeApi.interceptors.request.use(includeAzureJwt, (error) =>
  Promise.reject(error)
);

nodeApiBlob.interceptors.request.use(includeAzureJwt, (error) =>
  Promise.reject(error)
);

export const oldApi = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_OLD_API}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    changeOrigin: true,
  },
});

oldApi.interceptors.request.use(
  async (config) => {
    const token = await refreshAccessToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export const sam = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_SAM_URL}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    changeOrigin: true,
  },
});

export const containersApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CONTAINERS_API,
  headers: {
    'Content-Type': 'application/json',
    changeOrigin: true,
  },
});

containersApi.interceptors.request.use(includeAzureJwt, (error) =>
  Promise.reject(error)
);

export const containersApiFormData = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CONTAINERS_API,
  headers: {
    'Content-Type': 'multipart/form-data',
    changeOrigin: true,
  },
});

containersApiFormData.interceptors.request.use(includeAzureJwt, (error) =>
  Promise.reject(error)
);

export const containersApiBlob = axios.create({
  baseURL: process.env.NEXT_PUBLIC_CONTAINERS_API,
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/json',
  },
});

containersApiBlob.interceptors.request.use(includeAzureJwt, (error) =>
  Promise.reject(error)
);

export const api = axios.create({
  baseURL: '/api',
  headers: {
    'Content-Type': 'application/json',
    changeOrigin: true,
  },
});

export default {
  nodeApi,
  nodeApiBlob,
  containersApi,
  containersApiBlob,
};
