const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: { ...state.user, ...action.payload.user },
      };
    case 'SET_GROUPS':
      return {
        ...state,
        groups: action.payload.groups,
        user: { ...state.user, ...action.payload.user },
        userLoaded: true,
      };
    default:
      return state;
  }
};

export default reducer;
