import { Box, Button, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { Component } from 'react';
import { PaddedCard } from './ui-extension';
import { datadogRum } from '@datadog/browser-rum';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, errorMessage: '', stack: '' };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    this.setState({ errorMessage: error.message, stack: error.stack });
    console.error({ error, errorInfo });
    datadogRum.addError(error);
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Stack alignItems={'center'} spacing={3} sx={{ width: '100%' }}>
          <Typography variant='h4'>
            Stephen was a little different as a teenager
          </Typography>
          <Box
            sx={{
              maxWidth: '50%',
            }}
          >
            <Image src='/errors/stephen.jpg' width={1200} height={820}></Image>
            <PaddedCard>
              <Typography
                color='error'
                sx={{ width: '100%', fontWeight: 'bold' }}
              >
                {this.state.errorMessage}
              </Typography>
              <Typography sx={{ width: '100%' }}>{this.state.stack}</Typography>
            </PaddedCard>
          </Box>
          <Button
            onClick={() =>
              this.setState({ hasError: false, errorMessage: '', stack: '' })
            }
            color='error'
          >
            Try Again?
          </Button>
        </Stack>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
