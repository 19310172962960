import apiConnections, { oldApi } from './api';
const { nodeApi } = apiConnections;

export const EmployeeAPI = {
  getEmployee: (email, dispatch) =>
    nodeApi.get(`employees?email=${email}`).then((res) => {
      dispatch({
        type: 'SET_USER',
        payload: {
          user: res.data,
        },
      });
    }),
  getActiveEmployees: () => nodeApi.get('employees'),
  getSamSession: () => oldApi.get(`/internal/authorization/employee`),
};
