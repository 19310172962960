import styles from '../../styles/Home.module.css';
const Loader = (props) => {
  return (
    <span
      style={{
        verticalAlign: 'bottom',
      }}
    >
      <div {...props} className={styles.spinner}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </span>
  );
};

export default Loader;
